import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~7],
		"/admin/email-preview/[template_name]": [~30],
		"/admin/system-stats": [~31],
		"/admin/user": [~32],
		"/(app)/brak-budzetu": [~18,[2]],
		"/(app)/b/[id]/budzet": [8,[2,3]],
		"/(app)/b/[id]/konta": [9,[2,3]],
		"/(app)/b/[id]/moje-konto": [~10,[2,3]],
		"/(app)/b/[id]/platnosc": [~11,[2,3]],
		"/(app)/b/[id]/pulpit": [12,[2,3]],
		"/(app)/b/[id]/raporty/przeplyw-pieniedzy": [13,[2,3,4]],
		"/(app)/b/[id]/raporty/zestawienie-wydatkow": [14,[2,3,4]],
		"/(app)/b/[id]/raporty/zmiana-salda": [15,[2,3,4]],
		"/(app)/b/[id]/transakcje": [16,[2,3]],
		"/(app)/b/[id]/transakcje/planowane": [17,[2,3]],
		"/cennik": [33],
		"/(simple)/historia-zmian": [24,[5]],
		"/(auth)/logowanie": [~19],
		"/(auth)/nowe-konto": [~20],
		"/(simple)/plan-rozwoju": [25,[5]],
		"/podrecznik": [34,[6]],
		"/podrecznik/budzet": [35,[6]],
		"/podrecznik/grupy-i-koperty": [36,[6]],
		"/podrecznik/kategorie": [37,[6]],
		"/podrecznik/konta": [38,[6]],
		"/podrecznik/metoda-kopertowa": [39,[6]],
		"/podrecznik/oszczednosci": [40,[6]],
		"/podrecznik/pierwsze-kroki": [41,[6]],
		"/podrecznik/podstawowe-zasady": [42,[6]],
		"/podrecznik/transakcje": [43,[6]],
		"/podrecznik/wyplata-10tego": [44,[6]],
		"/(simple)/polityka-prywatnosci": [26,[5]],
		"/(paynow)/potwierdzenie-platnosci": [~23],
		"/(simple)/regulamin": [27,[5]],
		"/(auth)/resetuj-haslo": [~21],
		"/(auth)/resetuj-haslo/[token]": [~22],
		"/(simple)/usun-konto": [28,[5]],
		"/(simple)/usuwanie-konta": [29,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';